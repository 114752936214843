@import url(https://fonts.googleapis.com/css2?family=Noticia+Text:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Noticia Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  text-align: center;
  background-color: rgb(21, 32, 43);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-weight: 400;
}
h1 em {
  font-weight: 700;
  font-style: normal;
}

a {
  color: #FC1F8D;
}
a:visited {
  color: #C14D9D;
}
a:hover {
  color: #FCD209;
}

.img-circle {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  height: 200px;
  width: 200px;
}

